import Rest from '../Rest'

export default class LogoService extends Rest {

  /**
   * @type {String}
   */
  static resource = '/api/v1/logo'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

}
