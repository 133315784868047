<template>
  <div
    class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center"
    id="page-login"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row no-gutter justify-center items-center">
            <div class="vx-col hidden lg:block lg:w-1/2">
              <logo public/>
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg">
              <div class="vx-logo flex lg:hidden justify-items-center justify-center items-center mb-2 pt-4">
                <logo public/>
              </div>
              <div class="p-4 mb-3">
                <div class="vx-card__title mb-4 grid justify-items-center">
                  <h4 class="mb-3">Login Simulado</h4>
                  <p class="mb-3"> Não utilize esta funcionalidade se não for administrador, sujeito a bloqueio de sua conta. </p>
                </div>

                <vs-input
                  v-validate="'required|email|min:3'"
                  data-vv-validate-on="blur"
                  name="email"
                  icon="icon icon-user"
                  icon-pack="feather"
                  label-placeholder="Email"
                  v-model="email"
                  class="w-full no-icon-border mb-4"
                  :placeholder="$t('email')"
                  data-vv-as="Email"
                  @keyup.enter="simulate"
                />
                <span class="text-danger text-sm">{{ errors.first('email') }}</span>

                <vs-button class="float-right mb-4" :disabled="!validateForm" @click="simulate">Simular</vs-button>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>

import { popupCenter } from '@/util/Util'
import Logo from '@/layouts/components/Logo.vue'

export default {
  components: {
    Logo
  },
  data() {
    return {
      email: '',
      smallScreen: false
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== ''
    }
  },
  methods: {
    simulate() {
      this.$router.push(`/slogin/${this.email}`)
    },
    clearFields() {
      this.email = ''
    }
  },
  created() {
    this.smallScreen = this.isSmallScreen()
  },
  mounted() {
    window.addEventListener('message', this.loginListener, false)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.loginListener, false)
  }
}
</script>