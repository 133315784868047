<template>
  <div id="logo-container" class="vs-con-loading__container flex justify-center align-middle">
    <img v-if="institutionLogo"
        class="border-faint-grey border-solid border-l pl-2 border-t-0 border-b-0 border-r-0"
        :src="institutionLogo"
        :alt="$t('oganization-and-and-organization-name-or-or')"
        style="max-width: 100px;"/>
    <img
      v-else-if="!this.reduce && getLogo"
      :src="getLogo"
      alt="login"
      class="mx-auto"
      :style="logoStyleStr"
    />
    <img
      v-else-if="smallLogo"
      :src="smallLogo"
      alt="login"
      class="mx-auto"
      :style="logoStyleStr"
    />
    <img
      v-else
      :src="require('@/assets/images/picture-placeholder.png')"
      width="50"
    />
  </div>
</template>


<script>
import LogoService from '@/services/api/LogoService'
import { isEmpty } from '@/acl/util'

export default {
  props: {
    reduce: {
      type: Boolean,
      default: false
    },
    public: {
      type: Boolean,
      default: false,
    },
    institutionLogo: {
      type: String,
      default: null
    },
    logo: {
      type: String,
      default: null
    },
    logoStyle: {
      type: String,
      default: null
    },
  },

  data: () => ({
    logoService: null
  }),

  mounted() {
    
    const small = this.public ? 'public/small' : 'small'
    const normal = this.public ? 'public/' : '/'

    
    this.logoService = LogoService.build(this.$vs)

    const smallLogo = this.smallLogo
    const logo = this.logo

    // if (!logo) {
    //   this.$vs.loading({
    //     container: '#logo-container',
    //     scale: 0.5
    //   })
    // }

    if (smallLogo === null || logo === null) {

      // this.$vs.loading({
      //   container: '#logo-container',
      //   scale: 0.5
      // })

      // const getSmallLogo = this.logoService.get(small).then((response) => {
      //   this.$store.dispatch('common/updateSmallLogo', response)
      // })

      // const getLogo = this.logoService.get(normal).then((response) => {
      //   this.$store.dispatch('common/updateLogo', response)
      // })

      // const requests = Promise.all([getSmallLogo, getLogo])

      // requests.finally(() => {
      //   this.$vs.loading.close('#logo-container > .con-vs-loading')
      // })

    }    
  },

  computed: {
    organizationLogo() {
      return this._.get(this.activeInstitution, 'organization.logo')
    },

    getLogo() {
      if (this.logo) return this.logo
      return this.$store.getters['common/logo']
    },

    smallLogo() {
      return this.$store.getters['common/smallLogo']
    },

    logoStyleStr() {
      return this.logoStyle ? this.logoStyle : 'max-width: 90%; height: auto; max-height: 80px;'
    }

  }
}
</script>
